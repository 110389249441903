:root {
  color-scheme: light dark;
  --body-text: #212429;
  --card-bg: #dddddd;
  --body-background: #ffffff;
  --button-color: #
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-text: #e9e9e9;
    --card-bg: #111111;
    --body-background: #1f1f1f;
	--table-bg: #111111;
  }
}

@media (max-width: 767px) {
.Tag-Heading {
    font-weight: 500;
    font-size: 14pt;
    font-style: italic;
    margin-top: 1em;
    margin-bottom: -0.3em;
  }

  .Tag-Name {
    font-weight: 700;
    font-size: 40pt;
  }

  .Tag-Description {
    font-weight: 500;
    font-size: 18pt;
    margin-bottom: 1em;
  }
}

@media (min-width: 768px) {
  .Tag-Heading {
    font-weight: 500;
    font-size: 16pt;
    font-style: italic;
    margin-top: 1em;
    margin-bottom: -0.5em;
  }

  .Tag-Name {
    font-weight: 700;
    font-size: 60pt;
  }

  .Tag-Description {
    font-weight: 500;
    margin-bottom: 1em;
  }
}



.Tag-Card {
  background-color: var(--card-bg);
  color: var(--body-text);
  border-radius: 1em;
  margin-bottom: 2em;
  box-shadow: 0px 0px 5px black;
  transition: box-shadow 0.2s ease-in-out;
}

.Tag-Card:hover {
  box-shadow: 0px 0px 25px black;
  transition: box-shadow 0.2s ease-in-out;
}

.Tag-Card-Img {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.Tag-Bottom-Link {
  margin: 1em;
}
