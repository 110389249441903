.App {
  text-align: center;
}

.Hero-Image {
  max-width: 500px;
}

@media (max-width: 767px) {
  .NavbarTitleBrand {
    font-weight: 800;
  }
}

@media (min-width: 768px) {
  .NavbarTitleBrand {
    font-weight: 800;
  }
}

.NavbarTitleText {
  margin-left: 5pt;
  color: #eeeeee;
}

.NavbarTitleBrand:hover .Navbar-Title-Img {
  box-shadow: 0px 0px 25px black;
  transition: box-shadow 0.2s ease-in-out;
}

.NavbarTitleBrand:hover .NavbarTitleText {
  text-shadow: 0px 0px 25px black;
  transition: text-shadow 0.2s ease-in-out;
}
