:root {
  color-scheme: light dark;
  --body-text: #212429;
  --card-bg: #dddddd;
  --body-background: #ffffff;
  --button-color: #
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-text: #e9e9e9;
    --card-bg: #111111;
    --body-background: #1f1f1f;
	--table-bg: #111111;
  }
}

.Tag-Landing-Heading {
  font-weight: 800;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Tag-Landing-Footer {
  font-size: larger;
  margin-top: 1em;
  margin-bottom: 1em;
}

.Tag-Landing-Card {
  background-color: var(--card-bg);
  color: var(--body-text);
  border-radius: 1em;
  margin-bottom: 2em;
  box-shadow: 0px 0px 5px black;
  transition: box-shadow 0.2s ease-in-out;
}

@media (min-width: 992px) {
  .Tag-Landing-Card {
    margin-left: 12em;
    margin-right: 12em;
  }
}

.Tag-Landing-Card-Name {
  font-weight: 700;
  font-size: 18pt;
  margin-bottom: 0em;
}

.Tag-Landing-Card:hover {
  box-shadow: 0px 0px 25px black;
  transition: box-shadow 0.2s ease-in-out;
}
