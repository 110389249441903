:root {
  color-scheme: light dark;
  --body-text: #212429;
  --card-bg: #dddddd;
  --body-background: #ffffff;
  --button-color: #
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-text: #e9e9e9;
    --card-bg: #111111;
    --body-background: #1f1f1f;
	--table-bg: #111111;
  }
}

.Hero-Image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 499px) {
  .Hero-Image {
    max-width: 90%;
  }
}

@media (min-width: 501px) {
  .Hero-Image {
    max-width: 500px;
  }
}

.All-Cards-Heading {
  font-weight: 800;
  margin-top: 1em;
  margin-bottom: 1em;
}

.All-Cards-Card {
  background-color: var(--card-bg);
  color: var(--body-text);
  border-radius: 1em;
  margin-bottom: 2em;
  box-shadow: 0px 0px 5px black;
  transition: box-shadow 0.2s ease-in-out;
}

.All-Cards-Card:hover {
  box-shadow: 0px 0px 25px black;
  transition: box-shadow 0.2s ease-in-out;
}

.All-Cards-Card-Img {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.All-Cards-Bottom-Link {
  margin: 1em;
}

.All-Cards-Footer {
  margin-top: 3em;
  margin-bottom: 1.5em;
}