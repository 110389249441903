:root {
  color-scheme: light dark;
  --body-text: #212429;
  --body-background: #ffffff;
  --button-color: #
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-text: #e9e9e9;
    --body-background: #1f1f1f;
	--table-bg: #111111;
  }
}

body {
  margin: 0;
  padding: 0;
  padding-bottom: env(safe-area-inset-bottom);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--body-text);
  background-color: var(--body-background);
}

th {
	/* width: 120px; */
	/* max-height: 200px; */
	-webkit-print-color-adjust:exact;
}
@media (prefers-color-scheme: dark) {
	th {
		background-color: var(--table-bg);
	}

	.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
		border: 1px solid #545454 !important;
	}
}

tr {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@media (prefers-color-scheme: dark) {
  tr h3 {
	  color: var(--body-text)
  }

  tr a:link, tr a:visited {
	  color: #7c99af !important;
  }
}

.red-cell {
	background-color: #ff0000 !important; /* Or some other color */
	color: #ffffff;
	-webkit-print-color-adjust:exact;
}
.white-cell {
	background-color: #ffffff !important;
	color: #000000;
	-webkit-print-color-adjust:exact;
}
@media (prefers-color-scheme: dark) {
	.white-cell {
		background-color: var(--table-bg) !important;
		color: #ffffff;
		-webkit-print-color-adjust:exact;
	}
}
.blue-cell {
	background-color: #0000ff !important;
	color: #ffffff;
	-webkit-print-color-adjust:exact;
}
.total-cell {
	background-color: #1f1f1f !important;
	color: #ffffff;
	-webkit-print-color-adjust: exact;
}

@media (prefers-color-scheme: dark) {
	.total-cell {
		background-color: #272727 !important;
		color: #ffffff;
		-webkit-print-color-adjust: exact;
	}
}

.white-header {
	color: #343E47;
}
.white-header a:link, .white-header a:visited {
	color: #333F48 !important;
}

a:link, a:visited {
	color: #333F48;
}
@media (prefers-color-scheme: dark) {
  	a:link, a:visited {
		color: #7c99af;
	}	
}


.header-bg {
	background-color: #b7b7b7;
}
@media (prefers-color-scheme: dark) {
	.header-bg {
		background-color: #929292;
	}
}
.bold-header {
	font-weight: bolder;
}

.jumbotron-padding {
	padding: 0.8em;
}

@media screen and (max-width: 767px) {
	.row-th h3 {
		font-size: 90%;
	}
}

.row-heading {
	background-color: #dcdcdc;
}
@media (prefers-color-scheme: dark) {
  	.row-heading {
		background-color: #929292;
	}
}

.row-heading a:link,
.row-heading a:visited {
	color: #333F48 !important;
}

.total {
	background-color: #aaaaaa;
}

.ContestantIcon {
	width: 100px;
}

.footnote-text {
	font-size: x-large;
	margin-bottom: 1em;
}

.topnote-text {
	font-size: larger;
	padding-left: 1em;
	padding-right: 1em;
	margin-top: -1em;
	margin-bottom: 1em;
}

@media screen and (min-width: 768px) {
	.topnote-text {
			font-size: x-large;
			padding-left: 5em;
			padding-right: 5em;
		}
}
